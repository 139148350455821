.bg-bear {
  background-color: #ff8080;
  color: #333;
}

.bg-allyear {
  background-color: #000;
}

.container {
  perspective: 1000px;
}

.flip-container {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.flipped {
  transform: rotateY(180deg);
}

.flipper {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
}

.front {
  z-index: 2;
}

.back {
  transform: rotateY(180deg);
}

.fade-in {
  opacity: 0;
  animation: fadeInAnimation 0.5s ease-in forwards;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the content */
  height: 100%;
}

.flex-grow-1 {
  flex-grow: 1;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
