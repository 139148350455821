@media (max-width: 768px) {
  .navbar-collapse {
    font-size: 2rem !important;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeInAnimation 0.3s ease-in forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
