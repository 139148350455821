.Orders-header {
  margin-top: 20px;
}

.Orders-capital-container {
  background-color: #fff;
  height: 130px;
  width: 350px;
  border-radius: 8px;
}

.Orders-capital-content {
  width: 250px;
  background-color: #333;
  /* border: 1px solid #333; */
  border-radius: 8px;
  color: lightgrey;
  text-align: center;
  /* margin-right: 5px;
  margin-left: 5px; */
  /* margin-bottom: 10px; */
  padding: 12px;
  font-size: 1.2rem;
  /* box-shadow: 2px 6px 10px rgba(247, 246, 246, 0.2); */
}

.Orders-capital-investment {
  font-weight: 700;
}
