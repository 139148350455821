.Dashboard-content {
  background-color: lightsteelblue;
}

.Dashboard-menu {
  background-color: rgb(239, 237, 237);
  color: #000;
}

.Dashboard-leftmenu {
  margin-top: 20px;
  padding-left: 10px;
  height: 90vh;
}

.Dashboard-menu-item {
  padding: 5px;
  cursor: pointer;
}
