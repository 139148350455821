.faq-accordion {
  /* Your custom styles for the accordion container */
}

.faq-item {
  /* Your custom styles for the accordion items */
  color: #fff;
}

.faq-header {
  color: "#fff"; /* Your custom styles for the accordion headers */
}

.faq-button {
  /* Your custom styles for the accordion buttons */
  width: 100%;
  text-align: left;
  padding: 0.75rem 1.25rem;
  background-color: #333;
  color: lightgrey;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
  font-size: 1rem;
}

.faq-button.active {
  background-color: brown;
  color: #fff;
}

.faq-collapse {
  /* Your custom styles for the accordion collapse container */
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
}

.faq-collapse.show {
  max-height: 500px; /* Adjust height as needed */
}

.faq-body {
  padding: 20px;
  color: #fff;
}
