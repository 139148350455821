.dark-background {
  background-color: black;
  color: white;
  margin: 6px;
  cursor: pointer;
}

.width100 {
  width: 100px;
}

.width150 {
  width: 150px;
}

.width200 {
  width: 200px;
}

.width85 {
  width: 85px;
}

.width50 {
  width: 50px;
}
.width60 {
  width: 60px;
}

.light-red-bg {
  background-color: #ff8080;
}

.risk-management {
  background: linear-gradient(
    rgba(53, 155, 238, 0.2),
    rgba(28, 122, 200, 0.46)
  );
  /* Add any other styles you need */
}

.credentials {
  background: linear-gradient(
    rgba(28, 122, 200, 0.46),
    rgba(28, 122, 200, 0.46)
  );
  /* Add any other styles you need */
}

.danger-zone {
  background: linear-gradient(rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.46));
  /* Add any other styles you need */
}
