.fade-in {
  opacity: 0;
  animation: fadeInAnimation 0.5s ease-in forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
